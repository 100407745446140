<template>
	<Layout>
		<v-container>
      <v-row>
        <v-col>
					 <v-data-table
					 	dark
						:loading="loading"
						:headers="headers"
						:items="projectsList"
						:search="search"
          >
						<!-- slot Top table -->
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><h2><v-icon large class="mr-2">mdi-card-text</v-icon>PROJECT MANAGER</h2></v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn class="mt-2" fab dark color="secondary" @click.stop="newProject">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>
              <v-toolbar flat>
                <v-toolbar-items>
                  <v-text-field
										v-model="search"
										append-icon="mdi-magnify"
										label="Search"
										single-line
										hide-details
                  />
                </v-toolbar-items>
              </v-toolbar>
            </template>
						<!-- template User -->
            <template v-slot:item.user="{ item }">
              {{ item.user }}
            </template>
						<!-- template Active -->
            <template v-slot:item.active="{ item }">
              <v-layout justify-center>
                <v-switch
									color="success"
									v-model="item.active"
									@change="toogleActive(item)"
                />
              </v-layout>
            </template>
						<!-- columna accion -->
            <template v-slot:item.action="{ item }">
							<v-tooltip top>
								<span>Edit</span>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon class="action_button" @click.stop="editProject(item)">
											mdi-file-edit
										</v-icon>
									</v-btn>
								</template>
							</v-tooltip>
							<v-tooltip top>
								<span>Delete</span>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon class="action_button" @click="removeProject(item)" color="red">
											mdi-delete
										</v-icon>
									</v-btn>
								</template>
							</v-tooltip>
            </template>

					</v-data-table>
				</v-col>
			</v-row>
		</v-container>

		<project-dialog />
	</Layout>
</template>

<script>
	import axios from 'axios';
	import { mapState, mapActions } from "vuex";
	import store from '@/store'
	import ProjectDialog from '@/components/dialog/ProjectDialog.vue'
	const header = {"Token": store.state.authModule.token}
	const configuration = {headers: header}
	export default {
		name: 'ProjectView',
		components: { ProjectDialog },
		data: ()=>({
			projectsList: [],
			search: '',
			headers: [
				{ text: 'TITLE', value: 'title', align: 'start', width: '40%'},
				{ text: 'STATUS', value: 'status', align: 'start',  width: '15%'},
				{ text: 'USER', value: 'user.fullName', align: 'start', },
				{ text: 'COMPANY', value: 'user.company', align: 'start', },
				{ text: 'ACTIVE', value: 'active'},
				{text: '', value: 'action', sortable: false, width:'15%',lign: 'end', },
			],
			json_fields: {
				'PROJECT TITLE': 'title',
				'STATUS': 'status',
				'CUSTOMER': 'status',
			},
			items: [],
			editedIndex: -1,
			editedItem: [{
				_id: '',
				user: '',
				title: '',
				description: '',
				dataSet: '',
				status: '',
				dataSet: []
			}],
			dataSet: [],
			loading: false

		}),
		mounted(){
			this.getData()
		},
		methods: {
    	...mapActions('projectModule',['getProjects','deleteProject']),
			...mapActions('userModule',['getUsers']),

			async getData(){
				this.loading = true
				try {
					const projects = await this.getProjects()
					const users = await this.getUsers()
				} catch (error) {
					console.log(error)
				} finally {
					this.loading = false
				}
			},

			newProject(){
				this.$store.commit(
					'projectModule/SET_PROJECT',
					{
						_id: '',
						user: '',
						title: '',
						description: '',
						dataSet: '',
						status: '',
						dataSet: []
					},
					{ root: true }
				)
				this.$store.commit('SET_EDITMODE', 0)
				this.$store.commit('setDialog', true, { root: true })
			},
			editProject(item){
				this.editedItem = Object.assign({}, item)
				this.editedItem.customer = item.user
				this.$store.commit('SET_EDITMODE', 1)
				this.$store.commit('projectModule/SET_PROJECT', this.editedItem, { root: true } )
				this.$store.commit('setDialog', true, { root: true })
			},
			async toogleActive(item){
				let me = this;
				await axios.put('project/toggle-active', {'_id': item._id, 'active': item.active},configuration)
						.then(
								function (response) {
									me.$store.commit('SET_ALERT_MESSAGE', {
										show: true,
										color: 'success',
										message: 'Project Updated successfull',
										timeout: 4000
									}, {root: true})
								})
						.catch(function (e) {
							console.log(e.message)
						})
			},
			async removeProject(item){
				await this.$confirm('Are you sure you want to delete this Project?', {
					title: 'Warning',
					color: 'error',
					icon: 'mdi-hand',
					buttonTrueColor: 'primary',
				})
						.then(res => {
							if (res){
								const id = item._id
								this.deleteProject(id)
								const index = this.projects.findIndex(item => item._id === id);
								if (item.dataSet.length>0){
									for (let i = 0; i < item.dataSet.length; i++) {
										axios.post('project/remove-file', {
											fileName: item.dataSet[i].fileName,
										}, configuration)
									}
								}
								this.projects.splice(index, 1);
							}
						})
			}
		},
		computed: {
			//...mapState(['loading']),
			...mapState('projectModule',['projects','project']),
			userId(){
				return this.$store.state.authModule.user._id
			}
		},
		watch: {
			'projects': function () {
				this.projectsList = this.projects;
			}
		}

	}
</script>

