import store from '@/store'
import axios from "axios";
const header = {
	"Token": store.state.authModule.token,
	"Content-Type": "multipart/form-data"
}
const configuration = { headers: header }

class UploadFilesService {
	
	upload(file, onUploadProgress) {
		let formData = new FormData();

		formData.append("file", file);

		return axios.post("/project/upload", formData, {
			headers: {
				"Token": store.state.authModule.token,
				"Content-Type": "multipart/form-data"
			},
			onUploadProgress
		});
	}


}

export default new UploadFilesService();

//'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'