<template>
	<v-row justify="center">
		<!-- Project detail -->
		<v-dialog dark v-model="dialog" fullscreen  transition="dialog-bottom-transition">
			<v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ formTitle }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :loading="saving" dark text @click.stop="validate()" > <v-icon class="mr-3" color="white">mdi-check-circle</v-icon> SAVE</v-btn>
            <v-btn dark text @click="closeDialog"> <v-icon>mdi-close</v-icon> Cancel</v-btn>
          </v-toolbar-items>
        </v-toolbar>
				 <v-card-text>
          <v-container class="pt-5 rounded mt-5 elevation-10">
            <v-form ref="form" v-model="valid" enctype="multipart/form-data">
							<v-row>
                <v-col cols="12" sm="6" v-if="isAdmin">
									<v-select
                      class="mt-2"
                      v-model="editedItem.customer"
                      :items="users"
                      item-text="fullName"
                      item-value="_id"
                      return-object
                      label="Select User"
                  />
								</v-col>

								<v-col cols="12" sm="3" v-if="isAdmin">
									<v-select
										v-model="editedItem.status"
										:items="['SUBMITTED','PROCESSING','READY-TO-TEST']"
										prepend-icon="mdi-focus-field"
										menu-props="auto"
										hide-details
										label="STATUS"
                  />
								</v-col>
								<v-col cols="12">
									<v-textarea
										dense
										outlined
										v-model="editedItem.title"
										label="TITLE"
										:value="editedItem.title"
										rows="2"
										:rules="[rules.required]"
                  />
									<v-alert
										border="top"
										colored-border
										type="info"
										elevation="4"
									>
										We encourage you to provide us with information about your business case; problem, goals, the objective variable that you want to predict, optimize, minimize or maximize, the variables that you think weigh the most in your case, and a brief description of your business, so we can understand the context in which it takes place.
									</v-alert>
									<v-textarea
                      dense
                      filled
                      v-model="editedItem.description"
                      label="DESCRIPTION"
                      :value="editedItem.description"
                      rows="5"
                      :rules="[rules.required]"
                  />
									<h2>DATA SETS </h2>
								<v-btn
									class="mt-2" white--text color="primary"
									@click.stop="dialogDataSet = true"
								>
                  UPLOAD FILE  <v-icon class="ml-2" dark>mdi-plus</v-icon>
                </v-btn>
								<div> you can upload up to 5 files, only structured data formats (xls, xlsx, csv, txt, sql)</div>
								<div>Don’t forget to save the project after uploading all your files</div>

									<div class="pa-2"><v-divider /></div>
									<v-card
										class="mx-auto"
										max-width="90%"
										elevation="10"
										v-if="dataSets.length>0"
									>

										<v-simple-table>
											<template v-slot:default>
												<thead class="secondary">
													<tr>
														<th class="text-center">
															&nbsp;
														</th>
														<th class="text-left white--text">
															<h3>DATASETS</h3>
														</th>
														<th class="text-right">
															&nbsp;
														</th>
													</tr>
												</thead>
												<tbody>
													<tr
														v-for="(item,i) in dataSets"
														:key="i"
													>
														<td width="10">{{i+1}}</td>
														<td>{{ item.fileTitle }}</td>
														<td width="15%">
															<v-icon @click="downloadDataSet(item.fileName)" color="primary">mdi-download</v-icon>
															<v-icon @click="removeDataSet(item)" color="red">mdi-delete</v-icon>
														</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
										<!-- <v-card-actions class="grey lighten-3 pt-5" v-if="dataSets.length>0">
											<v-alert
												color="warning"
												dense
												dark
												icon="mdi-alert"
												border="right"
											>
												Don't forget save the project after upload all files
											</v-alert>
											<v-spacer/>
										</v-card-actions> -->
									</v-card>
								</v-col>
								<v-col class="text-right mt-5" v-if="dataSets.length>0">
											<v-btn :loading="saving" class="primary white--text" @click.stop="validate()" > <v-icon class="mr-3" color="white">mdi-check-circle</v-icon> SAVE PROJECT</v-btn>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				 </v-card-text>
			</v-card>
		</v-dialog>
		<!-- .Project detail -->

		<!-- dataSet upload -->
		<v-dialog dark  v-model="dialogDataSet" persistent max-width="470">
      <v-card class="blue-grey lighten-1">
        <v-card-title class="headline">Upload Excel File</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <v-file-input
									ref="fileData"
									@change="selectFile"
									show-size
									label="Upload File"
									hint="Only structured data formats (xls, xlsx, csv, txt, sql)"
									persistent-hint
								/>

              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn secondary text @click="dialogDataSet = false">Cancel</v-btn>
          <v-btn class="primary darken-2" :loading="uploading"  @click="uploadFile">Upload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
		<!-- .dataSet upload -->
	</v-row>
</template>

<script>
	import {mapState, mapActions} from 'vuex';
	import axios from "axios";
	const header = {"Token": localStorage.getItem("token") }
	const configuration = {headers: header}
	import UploadService from "@/services/UploadFilesService";
	export default {
		name: 'ProjectDialog',
		data: ()=>({
      uploading: false,
			saving: false,
      editedIndex: -1,
      valid: true,
      editedItem: {
				_id: '',
        user: '',
        title: '',
        description: '',
        status: 'SUBMITTED',
				dataSet: []
      },
			rules: {
				required: value => !!value || 'Item is required',
      },
			dialogDataSet: false,
			currentFile: undefined,
      progress: 0,
			fileTitle: '',
			fileName: '',
			message: '',
			dataSets: []
		}),
		mounted(){
		},
		methods: {
			...mapActions('projectModule',['getProjects','addProject','updateProject','removeFile','updateDataSet']),
			closeDialog() {
        this.$refs.form.resetValidation()
        this.dataSets = []
				this.$store.commit('setDialog', false, { root: true })
			},
			validate () {
				if (this.$refs.form.validate()) {
					this.saveProject()
				}
			},
			saveProject(){
				this.saving = true;
				this.editedItem.dataSet = this.dataSets
				if (this.editMode == 1) {
					this.updateProject(this.editedItem)
				} else {
					this.addProject(this.editedItem)
				}
				this.saving = false;
				this.editedItem={}
				this.$refs.form.resetValidation()
        this.dataSets = []
				this.closeDialog()
			},
			selectFile(file) {
				this.progress = 0;
				this.currentFile = file;
			},

      uploadFile() {
				let me=this;

				UploadService.upload(me.currentFile, (event) => {
					me.progress = Math.round((100 * event.loaded) / event.total);
				})
					.then((response) => {
						me.message = response.data.message;
						if (response.data.code === '200') {
							me.dataSets.push({
								index: me.indexDataSet,
								fileTitle:response.data.result.originalname,
								fileName: response.data.result.filename,
							})
							me.$store.commit('SET_ALERT_MESSAGE', {
								show: true,
								color: 'success',
								message: "File uploaded, don't forget save the project",
								timeout: 4000
							}, {root: true})
							me.dialogDataSet = false
							me.$refs.fileData.reset()
						}
					})
					.catch((e) => {
						console.log(e)
						me.progress = 0;
						me.message = "Could not upload the file!";
						me.currentFile = undefined;
						me.saving = false
						this.dialogDataSet = false
						me.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'red',
							message: me.message,
							timeout: 4000
						}, {root: true})
						return
					});
			},

			async removeDataSet(item){
				let me = this
				const idx = this.dataSets.findIndex(x => x.fileName === item.fileName);
				const fileName = this.dataSets[idx].fileName
				this.dataSets.splice(idx, 1);
				for (let i = 0; i < this.dataSets.length; i++) {
					this.dataSets[i].index = i+1
				}
				this.removeFile(fileName);
				if ( this.editMode == 1){
					this.updateDataSet({'id': me.editedItem._id, 'dataSet': me.dataSets})
				}
			},
			downloadDataSet(fileName){
				window.open(`${this.baseUrl}/files/${fileName}`,'_blank')
			}
		},
		computed: {
			...mapState(['loading', 'dialog', 'editMode']),
			...mapState('projectModule',['projects','project']),
			...mapState('userModule',['users','user']),
			formTitle () {
				return this.editMode == 1 ? 'EDIT PROJECT' : 'CREATE PROJECT'
			},
			isAdmin(){
				return this.$store.state.authModule.user.role;
			},
			indexDataSet(){
				return this.dataSets != null ? this.dataSets.length + 1 : 0;
			},
			baseUrl(){
				return process.env.VUE_APP_BASE_URL;
			}
		},
		watch: {
			'project': function() {
				if (this.project._id != ''){
					this.editedItem = this.project;
          if (this.editedItem.dataSet != null){
					  this.dataSets = this.editedItem.dataSet
          }
				}

			},
		}

	}
</script>

<style lang="scss" scoped>

</style>